import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LogIn from '../pages/Auth/LogIn';
import TermsAndConditionsPage from '../pages/Terms';
import { GuardModule } from './GuardModule';
import routesName, { moduleRoute } from '~routes/routes';
import { Layout } from '~templates/Layouts/Layout';
import Root from './Root';
import { Box } from '@mui/material';
import LinearProgress from '~ui/atoms/LinearProgress/LinearProgress';
import { useSelector } from 'react-redux';
import ThemeConfig from '~ui/themes';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '~pages/Dashboard/Dashboard'));
const Farmer = lazy(() => import(/* webpackChunkName: "Farmer" */ '~pages/Dashboard/Farmer'));
const ShowFarmer = lazy(() => import(/* webpackChunkName: "ShowFarmer" */ '~pages/Dashboard/Farmer/showFarmer'));

// Communication
const Notification = lazy(() => import(/* webpackChunkName: "Farmer" */ '~pages/Dashboard/communication'));
const ShowNotification = lazy(() =>
  import(/* webpackChunkName: "ShowFarmer" */ '~pages/Dashboard/communication/Notification/showNotification')
);
const ShowTeleAssistance = lazy(() =>
  import(/* webpackChunkName: "showTeleAssistance" */ '~pages/Dashboard/communication/Solicitude/showTeleAssistance')
);

const FarmersFileListLoaded = lazy(() =>
  import(/* webpackChunkName: "FarmersFileListLoaded" */ '~ui/pages/Dashboard/Farmer/pages')
);

const FarmersListLoaded = lazy(() =>
  import(/* webpackChunkName: "FarmersListLoaded" */ '~pages/Dashboard/Farmer/pages/MassiveLoad/ShowMassiveLoad')
);

const FarmersListLoadedProfile = lazy(() =>
  import(
    /* webpackChunkName: "FarmersListLoadedProfile" */ '~pages/Dashboard/Farmer/pages/MassiveLoadProfile/ShowMassiveLoadProfile'
  )
);

const FarmersListLoadedProductiveUnits = lazy(() =>
  import(
    /* webpackChunkName: "FarmersListLoadedProductiveUnits" */ '~pages/Dashboard/Farmer/pages/MassiveLoadProfile/ShowMassiveLoadProductiveUnits'
  )
);

const AgroLeaderPage = lazy(() => import(/* webpackChunkName: "AgroLederPage" */ '~pages/Dashboard/AgroLeader'));

const DisabledAgroLeaderPage = lazy(() =>
  import(/* webpackChunkName: "AgroLederPage" */ '~pages/Dashboard/AgroLeader/DisabledAgroLeader')
);

const ShowAgroLeaderPage = lazy(() =>
  import(/* webpackChunkName: "ShowAgroLederPage" */ '~pages/Dashboard/AgroLeader/ShowAgroLeader')
);

const OrganizationFormComponent = lazy(() =>
  import(/* webpackChunkName: "OrganizationFormComponent" */ '~ui/pages/Dashboard/OrganizationForm')
);

const ShowOrganizationFormComponent = lazy(() =>
  import(
    /* webpackChunkName: "ShowOrganizationFormComponent" */ '~ui/pages/Dashboard/OrganizationForm/EditOrganizationForm'
  )
);

const DataOrganizationFormComponent = lazy(() =>
  import(
    /* webpackChunkName: "ShowOrganizationFormComponent" */ '~ui/pages/Dashboard/OrganizationForm/DataOrganizationForm'
  )
);

const DisabledOrganizationFormComponent = lazy(() =>
  import(
    /* webpackChunkName: "ShowOrganizationFormComponent" */ '~ui/pages/Dashboard/OrganizationForm/DisabledOrganizationForm'
  )
);
const ResponseFormComponent = lazy(() =>
  import(/* webpackChunkName: "ResponseForm" */ '~ui/pages/Dashboard/OrganizationForm/ResponseForms')
);

// const MoreServicesComponent = lazy(() => import(/* webpackChunkName: "Service" */ '~ui/pages/Dashboard/Service'));

const OrganizationProfileComponent = lazy(() =>
  import(/* webpackChunkName: "OrganizationProfile" */ '~ui/pages/Dashboard/Organization/profile')
);

const CredentialPage = lazy(() => import(/* webpackChunkName: "CredentialPage" */ '~ui/pages/Dashboard/Credentials'));

const CredentialIdPage = lazy(() =>
  import(/* webpackChunkName: "CredentialIdPage" */ '~ui/pages/Dashboard/Credentials/ShowCredential')
);

const IssuedCredentialsPage = lazy(() =>
  import(/* webpackChunkName: "CredentialIdPage" */ '~ui/pages/Dashboard/Credentials/IssuedCredentials')
);

const ShareCredentialsPage = lazy(() =>
  import(/* webpackChunkName: "CredentialIdPage" */ '~ui/pages/Dashboard/Credentials/ShareCredentials')
);

const ShareCredentialsSelectionPage = lazy(() =>
  import(
    /* webpackChunkName: "CredentialIdPage" */ '~ui/pages/Dashboard/Credentials/ShareCredentials/CredentialsSelection'
  )
);

const DefaultCredentialPage = lazy(() =>
  import(/* webpackChunkName: "CredentialIdPage" */ '~ui/pages/Dashboard/Credentials/views/DefaultCredentials')
);

const PredeterminedCredentialPage = lazy(() =>
  import(/* webpackChunkName: "CredentialIdPage" */ '~ui/pages/Dashboard/Credentials/views/PredeterminedCredentials')
);

const FormCredentialPage = lazy(() =>
  import(/* webpackChunkName: "CredentialIdPage" */ '~ui/pages/Dashboard/Credentials/views/FormCredentials')
);

const FarmerRecordComponent = lazy(() =>
  import(/* webpackChunkName: "OrganizationProfile" */ '~ui/pages/Dashboard/Farmer/FarmerRecord')
);

const MapSelectionFormComponent = lazy(() =>
  import(/* webpackChunkName: "MapSelectionFormPage" */ '~ui/pages/Dashboard/OrganizationForm/MapSelectionForm')
);

const DirectoryRequestsComponent = lazy(() =>
  import(/* webpackChunkName: "DirectoryRequestsComponent" */ '~ui/pages/Dashboard/communication/Notification/DirectoryRequests')
);

//flow
const Flow = lazy(() => import(/* webpackChunkName: "flow" */ '~ui/pages/Dashboard/PanelFlow/pages/Flow'));
const FlowArchived = lazy(() =>
  import(/* webpackChunkName: "flow" */ '~ui/pages/Dashboard/PanelFlow/pages/FlowArchived')
);
const FlowEvent = lazy(() => import(/* webpackChunkName: "flow" */ '~ui/pages/Dashboard/PanelFlow/pages/Activator'));
const FlowDraw = lazy(() => import(/* webpackChunkName: "flowDraw" */ '~ui/pages/Dashboard/PanelFlow/panel/PaneView'));
const FlowResponse = lazy(() =>
  import(/* webpackChunkName: "flowResponse" */ '~ui/pages/Dashboard/PanelFlow/pages/Interaction')
);
const InteractionId = lazy(() => import('~ui/pages/Dashboard/PanelFlow/pages/Interaction/showInteraction'));
const FlowCampaign = lazy(() =>
  import(/* webpackChunkName: "flowCampaign" */ '~ui/pages/Dashboard/PanelFlow/pages/Flow/ShowCampaigns')
);
const FlowCampaignId = lazy(() =>
  import(/* webpackChunkName: "flowCampaignId" */ '~ui/pages/Dashboard/PanelFlow/pages/Flow/showInteractions')
);

const ProfitComponent = lazy(() => import(/* webpackChunkName: "ProfitComponent" */ '~pages/Dashboard/Profit'));
const ShowProducerProfitComponent = lazy(() =>
  import(/* webpackChunkName: "ShowProducerProfitComponent" */ '~pages/Dashboard/Profit/ShowProducerProfit')
);

const ProfitMassiveLoadComponent = lazy(() =>
  import(/* webpackChunkName: "ProfitMassiveLoadComponent" */ '~pages/Dashboard/Profit/pages/MassiveLoad')
);
const ProfitMassiveLoadShowComponent = lazy(() =>
  import(/* webpackChunkName: "ProfitMassiveLoadShowComponent" */ '~pages/Dashboard/Profit/pages/MassiveLoad/Show')
);

// VENTAS
const SalesPage = lazy(() => import(/* webpackChunkName: "SalesPage" */ '~ui/pages/Dashboard/SalesOrganization'));
const MakeSalePage = lazy(() =>
  import(/* webpackChunkName: "MakeSalePage" */ '~ui/pages/Dashboard/SalesOrganization/makeSalePage')
);
const SalesIdPage = lazy(() =>
  import(/* webpackChunkName: "SalesIdPage" */ '~ui/pages/Dashboard/SalesOrganization/saleDetail')
);
const QuotationPage = lazy(() =>
  import(/* webpackChunkName: "QuotationPage" */ '~ui/pages/Dashboard/SalesOrganization/QuotationOrganization')
);
const MakeQuotationPage = lazy(() =>
  import(
    /* webpackChunkName: "MakeSalePage" */ '~ui/pages/Dashboard/SalesOrganization/QuotationOrganization/makeQuotationPage'
  )
);

const ProductsPage = lazy(() =>
  import(/* webpackChunkName: "ProductsPage" */ '~ui/pages/Dashboard/ProductsOrganization')
);

const ProductStockPage = lazy(() =>
  import(/* webpackChunkName: "ProductStockPage" */ '~ui/pages/Dashboard/ProductsOrganization/StockProduct')
);

const InvoiceProducerPage = lazy(() =>
  import(/* webpackChunkName: "InvoiceProducerPage" */ '~ui/pages/Dashboard/Farmer/Components/InvoicePage')
);

const WrappedRoutes = () => (
  <Layout>
    <Suspense
      fallback={
        <Box width="100%">
          <LinearProgress loading={true} />
        </Box>
      }
    >
      <Routes>
        <Route
          path={routesName.dashboard}
          element={<GuardModule module_code={moduleRoute.dashboard.module_code} element={<Dashboard />} />}
        />
        <Route
          path={routesName.farmers}
          element={<GuardModule module_code={moduleRoute.farmers.module_code} element={<Farmer />} />}
        />
        <Route
          path={routesName.farmerId}
          element={<GuardModule module_code={moduleRoute.farmers.module_code} element={<ShowFarmer />} />}
        />

        <Route path={routesName.farmersFileListLoaded} element={<GuardModule element={<FarmersFileListLoaded />} />} />
        <Route path={routesName.farmersListLoaded} element={<GuardModule element={<FarmersListLoaded />} />} />
        <Route
          path={routesName.farmersListLoadedProfile}
          element={<GuardModule element={<FarmersListLoadedProfile />} />}
        />
        <Route
          path={routesName.farmersListLoadedProductiveUnits}
          element={<GuardModule element={<FarmersListLoadedProductiveUnits />} />}
        />

        <Route path={routesName.agroLeader} element={<GuardModule element={<AgroLeaderPage />} />} />

        <Route path={routesName.disabledAgroLeader} element={<GuardModule element={<DisabledAgroLeaderPage />} />} />

        <Route path={routesName.agroLeaderId} element={<GuardModule element={<ShowAgroLeaderPage />} />} />

        <Route path={routesName.organizationForm} element={<GuardModule element={<OrganizationFormComponent />} />} />
        <Route
          path={routesName.organizationFormEditId}
          element={<GuardModule element={<ShowOrganizationFormComponent />} />}
        />
        <Route
          path={routesName.organizationFormDataId}
          element={<GuardModule element={<DataOrganizationFormComponent />} />}
        />
        <Route
          path={routesName.organizationFormDisabled}
          element={<GuardModule element={<DisabledOrganizationFormComponent />} />}
        />
        <Route
          path={routesName.organizationFormResponse}
          element={<GuardModule element={<ResponseFormComponent />} />}
        />
        <Route
          path={routesName.organizationFormMapSelection}
          element={<GuardModule element={<MapSelectionFormComponent />} />}
        />
        <Route path={routesName.credential} element={<GuardModule element={<CredentialPage />} />} />
        <Route path={routesName.credentialId} element={<GuardModule element={<CredentialIdPage />} />} />
        <Route path={routesName.issuedCredentials} element={<GuardModule element={<IssuedCredentialsPage />} />} />
        <Route path={routesName.shareCredentials} element={<GuardModule element={<ShareCredentialsPage />} />} />
        <Route
          path={routesName.shareCredentialsSelection}
          element={<GuardModule element={<ShareCredentialsSelectionPage />} />}
        />
        <Route path={routesName.defaultCredentialsId} element={<GuardModule element={<DefaultCredentialPage />} />} />
        <Route
          path={routesName.predeterminedCredentialsId}
          element={<GuardModule element={<PredeterminedCredentialPage />} />}
        />
        <Route path={routesName.formCredentialsId} element={<GuardModule element={<FormCredentialPage />} />} />

        {/* Communication */}
        <Route path={routesName.comunication} element={<GuardModule element={<Notification />} />} />
        <Route path={routesName.notificationId} element={<GuardModule element={<ShowNotification />} />} />
        <Route path={routesName.callOutgoingId} element={<GuardModule element={<ShowTeleAssistance />} />} />

        <Route
          path={routesName.organizationProfile}
          element={<GuardModule element={<OrganizationProfileComponent />} />}
        />

        <Route path={routesName.farmerRedcord} element={<GuardModule element={<FarmerRecordComponent />} />} />
        <Route path={routesName.directoryReuqests} element={<GuardModule element={<DirectoryRequestsComponent />} />} />

        {/* FLOWS */}
        <Route
          path={routesName.flow}
          element={<GuardModule module_code={moduleRoute.flows.module_code} element={<Flow />} />}
        />
        <Route
          path={routesName.flowCampaignId}
          element={<GuardModule module_code={moduleRoute.flows.module_code} element={<FlowCampaign />} />}
        />

        <Route
          path={routesName.flowCampaignIdData}
          element={<GuardModule module_code={moduleRoute.flows.module_code} element={<FlowCampaignId />} />}
        />

        <Route
          path={routesName.flowArchived}
          element={<GuardModule module_code={moduleRoute.flows.module_code} element={<FlowArchived />} />}
        />
        <Route
          path={routesName.flowEvent}
          element={<GuardModule module_code={moduleRoute.flows.module_code} element={<FlowEvent />} />}
        />
        <Route
          path={routesName.flowResponse}
          element={<GuardModule module_code={moduleRoute.flows.module_code} element={<FlowResponse />} />}
        />
        <Route
          path={routesName.flowInteractionId}
          element={<GuardModule module_code={moduleRoute.flows.module_code} element={<InteractionId />} />}
        />
        <Route path={routesName.profit} element={<GuardModule element={<ProfitComponent />} />} />
        <Route
          path={routesName.producerProfit}
          element={<GuardModule element={<ShowProducerProfitComponent />} />}
          // key="producer_profit"
        />
        <Route
          path={routesName.massiveLoadProfit}
          element={<GuardModule element={<ProfitMassiveLoadComponent />} />}
          // key="massive_load_profit"
        />
        <Route
          path={routesName.showMassiveLoadProfit}
          element={<GuardModule element={<ProfitMassiveLoadShowComponent />} />}
          // key="show_massive_load_profit"
        />
        <Route path="*" element={<GuardModule element={<Navigate to={routesName.dashboard} />} />} />

        {/* // VENTAS */}
        <Route path={routesName.salesOrganization} element={<GuardModule element={<SalesPage />} />} />
        <Route path={routesName.makeSalePage} element={<GuardModule element={<MakeSalePage />} />} />
        <Route path={routesName.salesOrganizationId} element={<GuardModule element={<SalesIdPage />} />} />
        <Route path={routesName.productsOrganization} element={<GuardModule element={<ProductsPage />} />} />
        <Route path={routesName.productsOrganizationStock} element={<GuardModule element={<ProductStockPage />} />} />
        <Route path={routesName.farmerIdSaleId} element={<GuardModule element={<InvoiceProducerPage />} />} />
        <Route path={routesName.quotationOrganization} element={<GuardModule element={<QuotationPage />} />} />
        <Route path={routesName.makeQuotationPage} element={<GuardModule element={<MakeQuotationPage />} />} />
        <Route path={routesName.QuotationOrganizationId} element={<GuardModule element={<MakeQuotationPage />} />} />
      </Routes>
    </Suspense>
  </Layout>
);

const FlowRoute = () => (
  <ThemeConfig>
    <Suspense fallback={<LinearProgress loading={true} />}>
      <FlowDraw />
    </Suspense>
  </ThemeConfig>
);

const Router = () => {
  const { auth }: any = useSelector((state: any) => state);
  return (
    <Root>
      <Routes>
        <Route path={routesName.flowDrawId} element={<FlowRoute />} />
        <Route path="/" element={auth.isLoggedIn ? <Navigate to={routesName.dashboard} /> : <LogIn />} />
        <Route
          path="/terminos_y_condiciones"
          element={auth.isLoggedIn ? <Navigate to={routesName.dashboard} /> : <TermsAndConditionsPage />}
        />
        <Route path="/*" element={<GuardModule element={<WrappedRoutes />} />} />
      </Routes>
    </Root>
  );
};

export default React.memo(Router);
