// routes
// import { PATH_DASHBOARD } from '../../routes/paths';

const sidebarConfig = [
  {
    subheader: 'Visualización',
    items: [
      {
        title: 'Resumen',
        path: '/dashboard',
        icon: 'timeline'
      }
    ]
  },
  {
    subheader: 'PERFILES',
    items: [
      {
        title: 'Clientes',
        path: '/dashboard/farmers',
        icon: 'person',
        children: [
          {
            path: '/dashboard/file_summary',
            title: 'Archivos subidos'
          },
          {
            path: '/dashboard/farmer-record',
            title: 'Ficha del cliente'
          }
        ]
      },
      // {
      //   title: 'Utilidad',
      //   path: '/dashboard/profit',
      //   icon: 'money',
      //   children: [
      //     {
      //       path: '/dashboard/profit_massive_load',
      //       title: 'Archivos subidos'
      //     }
      //   ]
      // },
      {
        title: 'Agentes',
        path: '/dashboard/farm_agent',
        icon: 'group',
        children: [
          {
            path: '/dashboard/agentes_eliminados',
            title: 'Agentes eliminados'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Ventas',
    items: [
      {
        title: 'Inventario',
        path: '/dashboard/products',
        icon: 'widgets_outlined'
      },
      {
        title: 'Mis ventas',
        path: '/dashboard/sales',
        icon: 'shopping_cart'
      },
      {
        title: 'Cotizaciones',
        path: '/dashboard/quotation',
        icon: 'post_add'
      }
    ]
  },
  {
    subheader: 'Recolección de Datos',
    items: []
  },
  {
    subheader: 'APP CONNECT',
    items: [
      {
        title: 'Formularios',
        path: '/dashboard/organization_form',
        icon: 'description',
        children: [
          {
            path: '/dashboard/response_form',
            title: 'Respuestas de formularios'
          },
          {
            path: '/dashboard/organization_form_delete',
            title: 'Formularios eliminados'
          },
          {
            path: '/dashboard/map_selection',
            title: 'Seleccionar mapas'
          }
        ]
      }
    ]
  },
  {
    subheader: 'WHATSAPP',
    items: [
      {
        title: 'Formularios',
        path: '/dashboard/flows',
        icon: 'device_hub',
        title_header: 'Whatsapp - Formularios',
        children: [
          {
            path: '/dashboard/flows/response',
            title: 'Respuesta de Formularios',
            title_header: 'Whatsapp - Formularios'
          },
          {
            path: '/dashboard/flows/archived',
            title: 'Formularios archivados',
            title_header: 'Whatsapp - Formularios'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Central de Comunicación',
    items: [
      // {
      //   title: 'Mis asesorías',
      //   path: '/dashboard/mis_asesorias',
      //   icon: 'star-filled'
      // },
      {
        title: 'Comunicaciones',
        path: '/dashboard/communications',
        icon: 'message-filled',
        children: [
          {
            path: '/dashboard/directory_requests',
            title: 'Solicitudes de acceso a información de directorio'
          }
        ]
      }
    ]
  },
 
  // {
  //   subheader: 'CERTIFICADOS BLOCKCHAIN',
  //   items: [
  //     {
  //       title: 'Certificados blockchain',
  //       path: '/dashboard/certificados',
  //       icon: 'credit_card',
  //       children: [
  //         {
  //           path: '/dashboard/certificados_emitidos',
  //           title: 'Certificados blockchain emitidos'
  //         },
  //         {
  //           path: '/dashboard/compartir_certificados',
  //           title: 'Compartir certificados'
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    subheader: 'Configuraciones',
    items: [
      {
        title: 'Sobre la organización',
        path: '/dashboard/organization/profile',
        icon: 'settings'
      }
    ]
  }
];

export default sidebarConfig;
