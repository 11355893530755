const HOST_URL = process.env.REACT_APP_HOST_URL;
const HOST_API = process.env.REACT_APP_HOST_API;
const HOST_API_FORMS = process.env.REACT_APP_HOST_API_FORMS;
const API_URL_COMMUNICATION = process.env.REACT_APP_API_URL_COMMUNICATION;
const HOST_DIGITAL_IDENTITY_API = process.env.REACT_APP_HOST_DIGITAL_IDENTITY_API;
const HOST_API_SUITE = process.env.REACT_APP_HOST_API_SUITE;
const HOST_API_RENIEC = process.env.REACT_APP_HOST_API_RENIEC;
const ACCESS_KEY_ID = `${process.env.REACT_APP_ACCESS_KEY_ID}`;
const MAPBOX_TOKEN = `${process.env.REACT_APP_MAPBOX_TOKEN}`;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';

// FLOW
const API_URL_FLOW = process.env.REACT_APP_API_URL_FLOW;
const API_TEMPLATE_FLOW = process.env.REACT_APP_API_TEMPLATE_FLOW_TOOLS;
const API_URL_FLOW_CALL = process.env.REACT_APP_API_URL_FLOW_CALL;
const API_URL_BOT_FLOW = process.env.REACT_APP_API_URL_BOT_FLOW;
const API_URL_TELE_ASSISTANCE = process.env.REACT_APP_API_URL_TELE_ASSISTANCE;

const AWS_COGNITO_USER_POOL_ID = `${process.env.REACT_APP_USER_POOL_ID}`;
const AWS_IDENTITY_POOL_ID = `${process.env.REACT_APP_IDENTITY_POOL_ID}`;
const AWS_COGNITO_APP_CLIENT_ID = `${process.env.REACT_APP_CLIENT_ID}`;
const SECRET_ACCESS_KEY = `${process.env.REACT_APP_SECRET_ACCESS_KEY}`;
const AWS_REGION = `${process.env.REACT_APP_REGION}`;

const AWS_COGNITO_IDENTITY_POOL_ID = `${process.env.REACT_APP_IDENTITY_POOL_ID}`;

const JWT_PREFIX = `${process.env.REACT_APP_JWT_PREFIX}`;

//Sellers
const COMMUNITY_BASE_URL_S3 = `${process.env.REACT_APP_COMMUNITY_BASE_URL_S3}`;
const AUDIO_DURATION_TIME_IN_SECONDS = parseInt(`${process.env.REACT_APP_AUDIO_DURATION_TIME_IN_SECONDS}`);
const AUDIO_SIZE_IN_BYTES = parseInt(`${process.env.REACT_APP_AUDIO_SIZE_IN_BYTES}`);

//Otros
const TIME_ZONE = `${process.env.TIME_ZONE}`;

export {
  HOST_URL,
  HOST_API_RENIEC,
  AWS_COGNITO_USER_POOL_ID,
  AWS_IDENTITY_POOL_ID,
  AWS_COGNITO_APP_CLIENT_ID,
  ACCESS_KEY_ID,
  SECRET_ACCESS_KEY,
  AWS_REGION,
  HOST_API,
  HOST_DIGITAL_IDENTITY_API,
  AWS_COGNITO_IDENTITY_POOL_ID,
  COMMUNITY_BASE_URL_S3,
  JWT_PREFIX,
  TIME_ZONE,
  AUDIO_DURATION_TIME_IN_SECONDS,
  AUDIO_SIZE_IN_BYTES,
  API_URL_COMMUNICATION,
  MAPBOX_TOKEN,
  HOST_API_SUITE,
  SOCKET_URL,
  HOST_API_FORMS,
  API_URL_FLOW,
  API_TEMPLATE_FLOW,
  API_URL_FLOW_CALL,
  API_URL_BOT_FLOW,
  API_URL_TELE_ASSISTANCE
};
